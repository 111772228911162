import { TerminalUtil } from "../utils/checkSession";
import { session_instance } from "./ApiClient";
import { getSessionStorage, setSessionStorage } from "../../src/utils/storage";
const accessMap = {
	BRANCH: "Branch",
	TERMINAL: "TPAID",
	CHANNEL: "User",
};

export default async function checkChannelBranchTpa() {
	let isEnabledExpired = true;
	const terminal = TerminalUtil.get();
	const enabled_time = getSessionStorage("isEnabledTime");

	if (enabled_time) {
		const time_now = new Date();
		const storage_time = new Date(enabled_time);
		const expiration = new Date(storage_time.getTime() + 60000);
		if (time_now <= expiration) {
			isEnabledExpired = false;
		}
	}

	if (isEnabledExpired) {
		setSessionStorage("isEnabledTime", Date.now());
		const { data } = await session_instance.get(
			`/v3/channel/branch/terminal/${terminal.id}/is-enabled`
		);
		if (data.data.disabled) {
			return accessMap[data.data.disabled];
		}
		return null;
	}
}
