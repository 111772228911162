import React, { Component } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Button,
	RadioGroup,
	Radio,
	FormControlLabel,
	TextField,
	Grid,
	CircularProgress,
} from "@material-ui/core";
import styles from "./PrintReceiptFlow.module.css";
import HTTP from "../../helpers/ApiClient";
import { connect } from "react-redux";
import { togglePrintReceiptModal } from "../../redux/modules/receipt";
import { updateTransactionList } from "../../redux/modules/transaction";
import { isReprintReceiptRequestScope } from "../../utils/checkSession";
import { isReprintCheckRequestScope } from "../../utils/checkSession";
import {
	isWithoutApprovalReprintCheckRequestScope,
	isWithoutApprovalReprintReceiptRequestScope,
} from "../../utils/checkSession";
import _ from "lodash";
import { removeObjectSessionList} from "../../utils/storage";

class PrintReceiptFlow extends Component {
	constructor() {
		super();
		this.state = {
			isPrintReceiptSuccessful: undefined,
			receiptNo: undefined,
			reason: "",
			isReceiptNumberValid: true,
			isLoading: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.generatePreFilledReceiptNo =
			this.generatePreFilledReceiptNo.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmitButtonEnable() {
		const isPrintReceiptSuccessful = this.state.isPrintReceiptSuccessful;
		if (isPrintReceiptSuccessful === "no" && isPrintReceiptSuccessful) {
			if (this.state.reason !== "") {
				return false;
			}
			return true;
		}
		if (isPrintReceiptSuccessful === "yes") {
			return false;
		}
		if (isPrintReceiptSuccessful == undefined) {
			return true;
		}
	}

	handleChange(e) {
		const prefilledReceiptNo = this.generatePreFilledReceiptNo();
		this.setState({
			isPrintReceiptSuccessful: e.target.value,
			receiptNo: prefilledReceiptNo,
		});
	}

	resetStateComponent() {
		this.setState({
			isPrintReceiptSuccessful: undefined,
			receiptNo: undefined,
			reason: "",
			isReceiptNumberValid: true,
			isLoading: false,
		});
	}

	onInputChange(e) {
		const val = e.target.value;
		if (this.state.isPrintReceiptSuccessful === "yes") {
			this.setState({ receiptNo: val });
		} else {
			this.setState({ reason: val });
		}
	}

	async onSubmit() {
		try {
			this.setState({ isLoading: true });
			const receiptNo = this.props.receiptNo;
			const selectedTransaction =
				this.props.currentSelectedForPrint.values;
			
			const getTransactionRecord = this.props.isReprint
				? await HTTP.get(
						`/v2/receipts/transactions?transactionId=${selectedTransaction["crn"]}`
				  )
				: await HTTP.get(
						`/v2/receipts?transactionId=${selectedTransaction["crn"]}`
				  );
			
			const transactionDetails =
				getTransactionRecord["data"]["data"]["txn_receipts"] ||
				getTransactionRecord["data"]["data"];
			const isPrintTypeCheck = selectedTransaction.printType === "CHECK";
			
			let statusUpdateUrl = "";
			let validateReceiptUrl = "";
			let reprintEndPoint = "";
			let sameReceiptNo = true;
			if (isPrintTypeCheck) {
				if (isReprintCheckRequestScope()) {
					statusUpdateUrl = `v3/receipts/check/reprint/${selectedTransaction["crn"]}/status`;
				}
				validateReceiptUrl = `/v3/receipts/check/${selectedTransaction.receiptNo}`;
				// sameReceiptNo = selectedTransaction["checkReceiptNo"] === receiptNo;
				reprintEndPoint = "/v3/receipts/check/reprint";
			} else {
				if (isReprintReceiptRequestScope()) {
					statusUpdateUrl = `v2/receipts/reprint/${selectedTransaction["crn"]}/status`;
				}
				let txn_receipt_no = this.props.isReprint ? transactionDetails["print_receipt_no"] : receiptNo
				validateReceiptUrl = `/v2/receipts/${txn_receipt_no}`;
				// sameReceiptNo = selectedTransaction["receiptNo"] === txn_receipt_no;
				reprintEndPoint = "/v2/receipts/reprint";
			}
			const checker = () => {
				if (
					this.props.isReprint &&
					selectedTransaction.printType === "CHECK"
				) {
					if (
						selectedTransaction.scope === "bfa.branch.reprint.check"
					) {
						return isWithoutApprovalReprintCheckRequestScope();
					} else {
						return isReprintCheckRequestScope();
					}
				}
				if (
					this.props.isReprint &&
					selectedTransaction.printType === "CASH"
				) {
					if (
						selectedTransaction.scope === "bfa.transaction.reprint"
					) {
						return isWithoutApprovalReprintReceiptRequestScope();
					} else {
						return isReprintReceiptRequestScope();
					}
				}
			};
			checker();
			if (statusUpdateUrl) {
				HTTP.put(statusUpdateUrl, {
					status:
						this.state.isPrintReceiptSuccessful === "yes"
							? "PRINTED"
							: "FAILED",
					approver: selectedTransaction?.approver,
					remarks: selectedTransaction?.remarks,
				});
			}
			let body = {
				createdAt: selectedTransaction["createdAt"],
				action: this.props.isReprint ? "Reprint" : "Print" + " Receipt",
				isPrinted:
					this.state.isPrintReceiptSuccessful === "yes" ? "1" : "0",
				printCount: "0",
				receiptNo: "-",
				receiptType: selectedTransaction["paymentMethod"],
				remarks: selectedTransaction?.remarks,
				transactionRefNo: "-",
				username: this.props.isReprint
					? transactionDetails["username"]
					: transactionDetails[0]["username"],
			};

			let validateReceipt = "";
			if (this.state.isPrintReceiptSuccessful === "yes") {
				validateReceipt = await HTTP.get(validateReceiptUrl).catch(
					(err) => {
						return err.response;
					}
				);
				if (
					validateReceipt["data"]["statusCode"] === 404 ||
					!sameReceiptNo
				) {
					this.setState({
						isReceiptNumberValid: false,
						isLoading: false,
					});
					return;
				}

				const trasanction_details =
					validateReceipt["data"]["check_payment_detail"] ||
					validateReceipt["data"]["message"];
				if (validateReceipt["status"] === 200) {
					this.setState({ isReceiptNumberValid: true });

					body["printCount"] = 1;
					body["receiptNo"] = receiptNo;
					body["transactionRefNo"] =
						trasanction_details["transaction_id"];
					body["username"] = trasanction_details["username"];
				}
			}

			HTTP.post("/v2/admin/audit/log", body).catch((err) => {
				console.log(err);
			});
			if (!this.props.isReprint) {
				const transactionNumber =
					transactionDetails[0]["transaction_id"];
				const updateStatus = {
					status:
						this.state.isPrintReceiptSuccessful === "yes"
							? "PRINTED"
							: "FAILED",
				};

				HTTP.put(
					`/v2/receipts/transactions/${transactionNumber}/status`,
					updateStatus
				).catch((err) => {
					console.log(err);
				});

				this.props.updateTransactionList({
					index: this.props.currentSelectedForPrint.index,
					status: updateStatus,
				});
			} else {
				if (
					this.state.isPrintReceiptSuccessful === "yes" &&
					((!isReprintReceiptRequestScope() && !isPrintTypeCheck) ||
						(!isReprintCheckRequestScope() && isPrintTypeCheck))
				) {
					let requestBody = this.props.reprintRequestBody;
					HTTP.post(
						reprintEndPoint,
						JSON.stringify(requestBody)
					).catch((e) => {
						console.log(e);
					});
				}
			}
			if(selectedTransaction["paymentMethod"] === "CASH") removeObjectSessionList("transactionDetails", "receiptNo", receiptNo)
		} catch {
			this.setState({ isLoading: false });
		} finally {
			this.setState({ isLoading: false });
		}

		this.props.togglePrintReceiptModal(false);
		this.resetStateComponent();
	}

	generatePreFilledReceiptNo() {
		const selectedForPrint =
			this.props.currentSelectedForPrint &&
			this.props.currentSelectedForPrint.values;
		const date = new Date(selectedForPrint["createdAt"]) || new Date();
		let month = date.getMonth() + 1;
		month = ("0" + month).slice(-2);
		const prefilledReceiptNo = `${month}${date.getFullYear()}-`;
		return prefilledReceiptNo;
	}

	render() {
		return (
			<Dialog
				aria-labelledby="print-receipt-confirmation"
				open={this.props.showPrintReceiptModal}
				fullWidth={true}
				maxWidth={"sm"}
			>
				<Grid container alignItems="center" justify="center">
					<Grid item xs={12}>
						<DialogTitle
							id="print-receipt-confirmation"
							style={{ textAlign: "center" }}
						>
							{this.state.isPrintReceiptSuccessful ===
								undefined && (
								<Typography
									variant="h6"
									className={styles.modalTitle}
								>
									{" "}
									Receipt Printing Done!
								</Typography>
							)}
							{this.state.isPrintReceiptSuccessful !==
								undefined && (
								<Typography
									variant="h6"
									className={styles.modalTitle}
								>
									{" "}
									Receipt Printing Confirmation
								</Typography>
							)}
						</DialogTitle>
						<DialogContent
							dividers
							style={{ textAlign: "center", paddingTop: "30px" }}
						>
							<Typography className={styles.modalBody}>
								Is the receipt successfully printed?
							</Typography>
							<RadioGroup
								aria-label="print-result"
								name="print-result"
								row
								value={this.state.isPrintReceiptSuccessful}
								onChange={this.handleChange}
								style={{
									justifyContent: "center",
									padding: "10px 0 30px 0",
								}}
							>
								<FormControlLabel
									value="no"
									control={<Radio />}
									label={
										<Typography
											className={styles.modalBody}
										>
											No
										</Typography>
									}
									style={{ padding: "0 20px" }}
								/>
								<FormControlLabel
									value="yes"
									control={<Radio />}
									label={
										<Typography
											className={styles.modalBody}
										>
											Yes
										</Typography>
									}
									style={{ padding: "0 20px" }}
								/>
							</RadioGroup>

							{this.state.isPrintReceiptSuccessful === "no" && (
								<div style={{ padding: "30px 0 10px 0" }}>
									<TextField
										id="reason"
										name="reason"
										label="Reason"
										multiline
										rows={4}
										variant="outlined"
										fullWidth={true}
										inputProps={{ maxLength: 1000 }}
										InputLabelProps={{
											classes: {
												root: styles.modalBody,
											},
										}}
										InputProps={{
											classes: {
												input: styles.modalBody,
											},
										}}
										onChange={this.onInputChange}
									/>
								</div>
							)}
						</DialogContent>
						<DialogActions style={{ justifyContent: "center" }}>
							<Button
								disabled={
									this.onSubmitButtonEnable() ||
									this.state.isLoading
								}
								onClick={this.onSubmit}
								className={styles.enable}
							>
								{this.state.isLoading && (
									<CircularProgress
										size={18}
										style={{ marginRight: "6px" }}
									/>
								)}{" "}
								Submit
							</Button>
						</DialogActions>
					</Grid>
				</Grid>
			</Dialog>
		);
	}
}

export default connect(
	(state) => ({
		showPrintReceiptModal: state.receipt.showPrintReceiptModal,
		receiptNo: state.receipt.receiptNo,
		currentSelectedForPrint: state.transaction.selectedForPrint,
		transactionList: state.transaction.transactionList,
		reprintRequestBody: state.reprint.reprintRequestBody,
	}),
	{ togglePrintReceiptModal, updateTransactionList }
)(PrintReceiptFlow);
