import { gql } from "@apollo/client";

export const LOGIN_QUERY = gql`
	query loginQuery {
		loginQuery {
			id
		}
	}
`;

export const GET_LOGIN_REQUEST_USER = gql`
	query MyQuery($uuid: String!, $username: String!) {
		getLatestLoginRequestByUser(user_id: $uuid, username: $username) {
			branch {
				branch_id
				branch_name
			}
			created_at
			expires_in
			id
			login_status
			terminal {
				tpa_id
				terminal_id
			}
			user {
				user_id
				first_name
				last_name
			}
		}
	}
`;

export const GET_LOGIN_REQUEST_BRANCH = gql`
	query MyQuery(
		$branch_id: String!
		$filter: Filter = { limit: 10, inactive_only: false }
		$key_token: String = ""
	) {
		getLoginRequestsByBranch(
			data: {
				filter: $filter
				branch_id: $branch_id
				key_token: $key_token
			}
		) {
			data {
				created_at
				expires_in
				id
				login_status
				terminal {
					tpa_id
					terminal_id
				}
				user {
					first_name
					last_name
					user_id
					username
				}
				branch {
					branch_name
					branch_id
				}
			}
			prev_key_token
			next_key_token
			query_count
			__typename @include(if: false)
		}
	}
`;

export const GET_NOTIFICATIONS = gql`
	query MyQuery($branch_id: String!) {
		getAppNotifList(branch_id: $branch_id) {
			data {
				id
				type
				branch_id
				data
				notif_state
				created_at
			}
		}
	}
`;

export const ON_UPDATE_WALLET_BALANCE = gql`
	subscription MySubscription($wallet_id: String!) {
		onWalletBalanceUpdate(wallet_id: $wallet_id) {
			wallet_id
			wallet_balance
			wallet_status
			wallet_type
			wallet_threshold
			last_update
		}
	}
`;

export const ON_UPDATE_LOGIN_REQUEST = gql`
	subscription MySubscription($uuid: String!) {
		onUpdateLoginStatus(user_id: $uuid) {
			user {
				user_id
				first_name
				last_name
			}
			login_status
		}
	}
`;

export const ON_UPDATE_NOTIFICATION = gql`
	subscription MySubscription($branch_id: String!) {
		onCreateBranchAppNotif(branch_id: $branch_id) {
			type
			branch_id
			user_id
			txn_no
			notif_state
		}
	}
`;

export const ON_UPDATE_TELLER_REPRINT_NOTIFICATION = gql`
	subscription MySubscription($user_id: String!) {
		onUpdateReprintStatus(user_id: $user_id) {
			notif_state
			txn_no
			type
			user_id
			receipt_no
		}
	}
`;

export const ON_CREATE_LOGIN_REQUEST = gql`
	subscription MySubscription($branch_id: String!) {
		onCreateLoginRequest(branch_id: $branch_id) {
			branch_id
			login_status
		}
	}
`;

export const CREATE_LOGIN_REQUEST_MUTATION = gql`
	mutation MyMutation(
		$branchId: String!
		$terminalId: String!
		$tpaId: String!
		$uuid: String!
		$username: String!
		$firstname: String!
		$lastname: String!
	) {
		createLoginRequest(
			data: {
				branch_id: $branchId
				terminal_id: $terminalId
				tpa_id: $tpaId
				user_id: $uuid
				username: $username
				first_name: $firstname
				last_name: $lastname
			}
		) {
			branch {
				branch_id
				branch_name
			}
			created_at
			expires_in
			id
			login_status
			terminal {
				tpa_id
				terminal_id
			}
			user {
				user_id
				first_name
				last_name
			}
		}
	}
`;

export const CANCEL_LOGIN_REQUEST = gql`
	mutation MyMutation(
		$uuid: String!
		$branchId: String!
		$id: ID!
		$tpaId: String!
	) {
		updateLoginStatus(
			data: {
				user_id: $uuid
				branch_id: $branchId
				id: $id
				login_status: CANCELLED
				tpa_id: $tpaId
			}
		) {
			branch {
				branch_id
				branch_name
			}
			created_at
			expires_in
			id
			login_status
			terminal {
				tpa_id
				terminal_id
			}
			user {
				user_id
				first_name
				last_name
			}
		}
	}
`;

export const APPROVE_LOGIN_REQUEST = gql`
	mutation MyMutation(
		$uuid: String!
		$branchId: String!
		$id: ID!
		$tpaId: String!
	) {
		updateLoginStatus(
			data: {
				user_id: $uuid
				branch_id: $branchId
				id: $id
				login_status: APPROVED
				tpa_id: $tpaId
			}
		) {
			branch {
				branch_id
				branch_name
			}
			created_at
			expires_in
			id
			login_status
			terminal {
				tpa_id
				terminal_id
			}
			user {
				user_id
				first_name
				last_name
			}
		}
	}
`;

export const DECLINE_LOGIN_REQUEST = gql`
	mutation MyMutation(
		$uuid: String!
		$branchId: String!
		$id: ID!
		$tpaId: String!
	) {
		updateLoginStatus(
			data: {
				user_id: $uuid
				branch_id: $branchId
				id: $id
				login_status: DECLINED
				tpa_id: $tpaId
			}
		) {
			branch {
				branch_id
				branch_name
			}
			created_at
			expires_in
			id
			login_status
			terminal {
				tpa_id
				terminal_id
			}
			user {
				user_id
				first_name
				last_name
			}
		}
	}
`;

export const INVALID_LOGIN_REQUEST = gql`
	mutation MyMutation(
		$uuid: String!
		$branchId: String!
		$id: ID!
		$tpaId: String!
	) {
		updateLoginStatus(
			data: {
				user_id: $uuid
				branch_id: $branchId
				id: $id
				login_status: INVALID
				tpa_id: $tpaId
			}
		) {
			branch {
				branch_id
				branch_name
			}
			created_at
			expires_in
			id
			login_status
			terminal {
				tpa_id
				terminal_id
			}
			user {
				user_id
				first_name
				last_name
			}
		}
	}
`;

export const UPDATE_LOGIN_STATUS_SUBS = gql`
	subscription MySubscription($uuid: String!) {
		onUpdateLoginStatus(user_id: $uuid) {
			branch_id
			notif_state
			user_id
		}
	}
`;

export const TEST_SUBS = gql`
	subscription MySubscription {
		onTestSub
	}
`;

export const ON_UPDATE_TERMINAL_STATUS = gql`
	subscription MySubscription($tpaId: String!) {
		onUpdateTerminalIsEnable(terminal_id: $tpaId) {
			terminal_id
			is_enabled
		}
	}
`;

export const ON_UPDATE_BRANCH_STATUS = gql`
	subscription MySubscription($branchId: String!) {
		onUpdateBranchIsEnable(branch_id: $branchId) {
			is_enabled
			branch_id
		}
	}
`;

export const ON_UPDATE_ACCOUNT_STATUS = gql`
	subscription MySubscription($channelId: String!) {
		onUpdateChannelIsEnable(channel_id: $channelId) {
			channel_id
			is_enabled
		}
	}
`;


export const ON_UPDATE_BRANCH_ACCESS_SCHEDULES = gql`
	subscription OnUpdateBranchAccessSchedules($user_id: String!) {
		onUpdateBranchAccessSchedules(user_id: $user_id) {
			branches {
				id
				status
				enabled_terminals
				total_terminals
				is_operating
				terminals {
					id
					status
					is_operating
				}
			}
		}
	}
`;