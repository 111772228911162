import { useSubscription } from "@apollo/client";
import { BranchUtil, TerminalUtil } from "../../utils/checkSession";
import {
	ON_UPDATE_ACCOUNT_STATUS,
	ON_UPDATE_BRANCH_STATUS,
	ON_UPDATE_TERMINAL_STATUS,
} from "../../graphql/queries";
import { useEffect, useState } from "react";
import AccessDeniedDialog from "../Dialog/Dialog";
import failedIcon from "../../assets/icons/ic-failed.svg";
import { accessErrorMessages, AccessObject } from "../Login/AccessChecker";
import { logOut } from "../../utils/logout";
import { getSessionStorage } from "../../utils/storage";
import { useSelector } from "react-redux";

const useSessionAccess = () => {
	const [branch] = BranchUtil.get();
	const terminal = TerminalUtil.get();
	const accountId = getSessionStorage("accountId");
	const [disabled, setDisabled] = useState<AccessObject | null>(null);

	const { disabledAccessTo } = useSelector(
		(state: any) => state.sessionAccess
	);

	const { data: tpaStatus } = useSubscription(ON_UPDATE_TERMINAL_STATUS, {
		variables: { tpaId: terminal.id },
		context: { clientName: "reprint" },
	});

	const { data: branchStatus } = useSubscription(ON_UPDATE_BRANCH_STATUS, {
		variables: { branchId: branch.id },
		context: { clientName: "reprint" },
	});

	const { data: channelStatus } = useSubscription(ON_UPDATE_ACCOUNT_STATUS, {
		variables: { channelId: accountId },
		context: { clientName: "reprint" },
	});

	useEffect(() => {
		const branchEnabled =
			branchStatus?.onUpdateBranchIsEnable?.is_enabled ?? true;
		const terminalEnabled =
			branchStatus?.onUpdateTerminalIsEnable?.is_enabled ?? true;
		const channelEnabled =
			branchStatus?.onUpdateChannelIsEnable?.is_enabled ?? true;

		let disabledAccess: AccessObject | null = null;
		if (!branchEnabled) disabledAccess = "Branch";
		if (!terminalEnabled) disabledAccess = "TPAID";
		if (!channelEnabled) disabledAccess = "User";

		setDisabled(disabledAccess);
	}, [channelStatus, branchStatus, tpaStatus]);

	return disabledAccessTo ?? disabled;
};

const SessionAccessMonitor = () => {
	const isDisabled = useSessionAccess();

	if (isDisabled) {
		return (
			<AccessDeniedDialog
				imgSrc={failedIcon}
				open={true}
				toggleDialog={() => logOut(false)}
				title="Access Denied!"
				message={accessErrorMessages[isDisabled]}
			/>
		);
	}

	return null;
};

export default SessionAccessMonitor;
