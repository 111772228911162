import { useEffect, useState, useRef, useMemo } from "react";
import RootRef from "@material-ui/core/RootRef";
import {
	FormControl,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Select,
	Tab,
	Tabs,
	Typography,
	Badge,
	Box,
	Alert,
	InputLabel,
	ListItemSecondaryAction,
	Grid,
	TextField,
	InputAdornment,
	CircularProgress,
	Tooltip,
	Fade,
	LinearProgress,
} from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteIcon from "@mui/icons-material/FavoriteRounded";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { ReactComponent as AllBillerIcon } from "../../assets/icons/all-biller.svg";
import { ReactComponent as AllBillerIconActive } from "../../assets/icons/all-biller-active.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/clear-search.svg";
import { ReactComponent as SortUpIcon } from "../../assets/icons/sort-up.svg";
import { ReactComponent as SortDownIcon } from "../../assets/icons/sort-down.svg";
import { ReactComponent as AddBillerIcon } from "../../assets/icons/add-biller.svg";
import { ReactComponent as AddBillerActiveIcon } from "../../assets/icons/add-biller-active.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import debounce from "lodash/debounce";
import "./BillerListPanel.css";
import HTTP from "../../helpers/ApiClient";
import _ from "lodash";
import {
	billerActionCreator,
	listBillers,
	resetCustomBillerServiceFee,
} from "../../redux/modules/billerList";
import { useSelector, useDispatch } from "react-redux";
import { resetValidated } from "../../redux/modules/validation";
import { getSessionStorage, setSessionStorage } from "../../utils/storage";
const notificationMessage = {
	add: " has been successfully added to favorites.",
	remove: " has been successfully removed from favorites.",
	move: " has been successfully moved.",
	failed: "Sorry, we encountered a problem upon doing this request. ",
	max: "Only a max of 20 partners can be added to favorites.",
};
// SX
const styles = {
	tab: {
		textTransform: "None",
		"&.Mui-selected": { color: "#FB7823" },
		minHeight: "50px",
		height: "50px",
		alignItems: "start",
		font: "normal normal normal 14px/16px Poppins-Medium !important",
	},
	categoryWrapper: {
		marginBlock: "5px",
		display: "grid",
		gridTemplateColumns: "40%  min-content",
		gap: "5px",
	},
	categoryLabel: {
		color: "black",
		margin: "auto",
	},
	alertSucess: {
		background: "green",
		color: "white",
		borderRadius: "10px",
		paddingTop: "7px",
		paddingBottom: "7px",
		paddingRight: "7px",
		width: "calc(100% - 30px)",
	},
	alertError: {
		background: "#D32F2F",
		color: "white",
		borderRadius: "10px",
		paddingTop: "7px",
		paddingBottom: "7px",
		paddingRight: "7px",
		width: "calc(100% - 30px)",
	},
	searchField: {
		padding: "10px",
		height: "40px",
		backgroundColor: "#F5F5F5",
		borderRadius: "5px",
		font: "normal normal normal 14px/16px Poppins-Regular",
		"& .MuiInput-underline:after": {
			borderBottomColor: "orange",
		},
		underlineColor: {
			"& .MuiInput-underline:after": {
				borderBottomColor: "#fb7823",
			},
		},
	},
	billerLoading: {
		height: "2px",
		width: "100%",
		overflow: "hidden",
		backgroundColor: "#FB7823",
		"& .MuiLinearProgress-bar": {
			backgroundColor: `#FAA268`,
		},
	},
	itemDestination: {
		borderStyle: "dashed",
		borderWidth: "2px",
		borderColor: "#FFBE38",
	},
};
function BillerListPanel(props) {
	const [isLoading, setLoading] = useState(true); // Get biller list loading
	const [dragDestination, setDragDestination] = useState();
	const [billerSelected, setBillerSelected] = useState("");
	const [requestedBiller, setRequestedBiller] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [billerList, setBillerList] = useState([]);

	const [categoryList, setCategoryList] = useState([]);
	const [favoritesCount, setFavoritesCount] = useState(0);
	const [loadingIndicator, setLoadingIndicator] = useState(false);
	const [notificationState, setNotificationState] = useState({
		name: "",
		isLoading: false,
		status: false,
		type: 0,
		message: "",
	});
	const searchRef = useRef();
	const [filterState, setFilterState] = useState({
		favorites: [],
		isSortAsc: true,
		search: "",
		filterFavorite: false,
		selectedCategory: "All",
	});
	const { filterFavorite, isSortAsc, selectedCategory } = filterState;
	const dispatch = useDispatch();
	const { billers, isLoadingSelectedBiller, isBillerSuccess } = useSelector(
		(store) => store.billerList
	);
	const requiredKeys = [
		"name",
		"code",
		"category",
		"logo",
		"isFavorite",
		"orderFavorite",
	];
	const filterFavoriteBillers = (billers) => {
		billers = _.filter(billers, ["isFavorite", 1]);
		return billers;
	};
	const filterChange = () => {
		var filteredList = [...billers];
		setFavoritesCount(filterFavoriteBillers(filteredList).length);
		// category
		if (selectedCategory !== "All" && !filterFavorite) {
			filteredList = filteredList.filter((biller) => {
				return (
					biller.category
						.toLowerCase()
						.indexOf(selectedCategory.toLowerCase()) !== -1
				);
			});
		}
		if (filterFavorite) {
			let favoriteBillers = filterFavoriteBillers(filteredList);
			favoriteBillers = _.sortBy(
				favoriteBillers,
				["orderFavorite"],
				["asc"]
			);
			filteredList = favoriteBillers;
		}
		// Sort
		if (!filterFavorite) {
			if (isSortAsc == false) {
				filteredList = filteredList.sort(function (a, b) {
					return b.name.localeCompare(a.name);
				});
			}
			// search
			filteredList = filteredList.filter((biller) => {
				return (
					biller.name
						.toLowerCase()
						.indexOf(searchRef.current.value.toLowerCase()) !== -1
				);
			});
		}
		setBillerList(filteredList);
	};
	function updateFavorites(tempBillers, billerName, action = "") {
		setBillerSelected(billerName);
		setLoadingIndicator(true);
		const payload = favoriteListRequest(tempBillers);
		HTTP.put("v2/billers/favorites", {
			data: payload,
		})
			.then((response) => {
				mutateNotificationState({
					name: billerName,
					isLoading: false,
					status: true,
					type: 1,
					message: notificationMessage[action],
				});
				setDragDestination("");
				setLoadingIndicator(false);
				dispatch(
					listBillers({
						billers: tempBillers,
					})
				);
				setSessionStorage("billers", tempBillers);
				setBillerList(tempBillers);
				getCategoryList(tempBillers);
			})
			.catch((error) => {
				setDragDestination("");
				setLoadingIndicator(false);
				mutateNotificationState({
					name: billerName,
					isLoading: false,
					status: true,
					type: 0,
					message: notificationMessage.error,
				});
			});
	}
	// check if a value can be parsed as JSON and valid array of object
	function isValidArrayOfObjects(value) {
		try {
			const parsedValue = JSON.parse(value);
			return (
				Array.isArray(parsedValue) &&
				parsedValue.length > 0 &&
				parsedValue.every(
					(item) => typeof item === "object" && item !== null
				)
			);
		} catch (error) {
			return false; // If parsing as JSON fails, return false
		}
	}
	function hasRequiredKeys(arrayOfObjects) {
		// Check each object in the array
		return arrayOfObjects.every((obj) => {
			// Check if all required keys are present in the object
			return requiredKeys.every((key) => key in obj);
		});
	}
	function hasValue(value) {
		if (value.length !== 0) {
			return true;
		}
		return false;
	}
	function setCachedExpiration() {
		const date = new Date();
		const minute = 5;
		const expiration = date.setTime(date.getTime() + minute * 60_000);
		setSessionStorage("cachedExpiration", expiration);
	}
	function isCachedExpired() {
		let result = true;
		const expiration = getSessionStorage("cachedExpiration");
		if (expiration) {
			const time_now = new Date();
			if (time_now <= expiration) {
				result = false;
			}
		}
		return result;
	}
	async function getBillerList() {
		let cachedBillers = [];
		setLoading(true);
		// checking valid cached biller list from session storage
		if (isValidArrayOfObjects(sessionStorage.getItem("billers"))) {
			const parsedValue = JSON.parse(sessionStorage.getItem("billers"));
			if (hasRequiredKeys(parsedValue)) {
				cachedBillers = parsedValue;
			}
		}
		if (hasValue(cachedBillers) && isCachedExpired() == false) {
			dispatch(
				listBillers({
					billers: cachedBillers,
				})
			);
			setSessionStorage("billers", cachedBillers);
			setBillerList(cachedBillers);
			getCategoryList(cachedBillers);
		} else {
			await HTTP.get("/v2/billers", {
				params: { category: "", orderBy: "name", orderDir: "asc" },
			})
				.then((response) => {
					const billerList = response.data.data.map((x) => ({
						...x,
						isSelected: false,
					}));
					cachedBillers = billerList;
					setSessionStorage("billers", cachedBillers);
					setCachedExpiration();
					dispatch(
						listBillers({
							billers: cachedBillers,
						})
					);
					setBillerList(cachedBillers);
					getCategoryList(cachedBillers);
				})
				.catch((error) => {
					console.log(error);
				});
		}
		setLoading(false);
	}

	const getCategoryList = (billerList) => {
		if (billerList && billerList.length > 0) {
			let categoryList = _.uniqBy(billerList, "category").map(
				(data) => data.category
			);
			setSessionStorage("billerCategories", categoryList);
			setCategoryList(categoryList);
		}
	};
	const mutateFilterState = (object) => {
		setFilterState({ ...filterState, ...object });
	};
	const mutateNotificationState = (object) => {
		setNotificationState({ ...notificationState, ...object });
	};
	const handleTabChange = () => {
		mutateFilterState({ filterFavorite: !filterFavorite });
	};
	const handleSortChange = () => {
		mutateFilterState({ isSortAsc: !isSortAsc });
	};
	const handleSelectedCategoryChange = (event) => {
		mutateFilterState({ selectedCategory: event.target.value });
	};
	const handleSearchChange = () => {
		setSearchValue(searchRef.current.value);
		filterChange();
	};
	const debounceOnChange = useMemo(
		() => debounce(handleSearchChange, 350),
		[searchValue, billers]
	);
	function favoriteListRequest(list) {
		// filter favorites from biller list
		let favorites = filterFavoriteBillers(list);
		// sort by correct order
		favorites = _.sortBy(favorites, ["orderFavorite"], ["asc"]);
		// get biller codes only
		favorites = favorites.map((item) => {
			return item.code;
		});
		// format list of biller
		favorites = `['${favorites.join(`','`)}']`;
		return favorites;
	}
	const handleDragFavorite = (source, destination) => {
		// swap position
		let tempBillers = billers;
		tempBillers = tempBillers.map((a) => {
			var returnValue = { ...a };
			if (a.orderFavorite == source[0].orderFavorite) {
				returnValue.orderFavorite = destination[0].orderFavorite;
			}
			if (a.orderFavorite == destination[0].orderFavorite) {
				returnValue.orderFavorite = source[0].orderFavorite;
			}
			return returnValue;
		});
		setDragDestination(destination[0].code);
		updateFavorites(tempBillers, source[0].name, "move");
	};
	const handleAddToFavorites = (biller) => {
		if (favoritesCount >= 20) {
			mutateNotificationState({ status: false });
			mutateNotificationState({
				name: "",
				isLoading: false,
				status: true,
				type: 0,
				message: notificationMessage.max,
			});
			return;
		}
		const { code, name } = biller;
		// Adding the favorite tag of the biller requested
		let tempBillers = billers;
		tempBillers = tempBillers.map((item) => {
			var returnValue = { ...item };
			if (item.code == code) {
				returnValue.orderFavorite = favoritesCount + 1;
				returnValue.isFavorite = 1;
			}
			return returnValue;
		});
		updateFavorites(tempBillers, name, "add");
	};
	const handleUnFavorite = (biller) => {
		const { code, name } = biller;
		let newBillers = billers;
		// Removing the favorite tag of the biller requested
		newBillers = newBillers.map((item) => {
			var returnValue = { ...item };
			if (item.code == code) {
				returnValue.orderFavorite = 0;
				returnValue.isFavorite = 0;
			}
			return returnValue;
		});
		// Correcting orderFavorite value
		let tempBillers = filterFavoriteBillers(newBillers);
		tempBillers = _.sortBy(tempBillers, ["orderFavorite"], ["asc"]);
		tempBillers.forEach((item, index) => {
			item.orderFavorite = index + 1;
		});
		newBillers = newBillers.map((item) => {
			var returnValue = { ...item };
			tempBillers.forEach((favItem) => {
				if (item.code == favItem.code) {
					return { ...favItem };
				}
			});
			return returnValue;
		});
		updateFavorites(newBillers, name, "remove");
	};
	function handleSelectedBiller(biller) {
		setLoadingIndicator(true);
		const { code, name } = biller;
		setRequestedBiller(code);
		setBillerSelected(name);
		try {
			dispatch(
				billerActionCreator({
					code: biller.code,
				})
			);
			dispatch(resetCustomBillerServiceFee());
			dispatch(resetValidated());
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingIndicator(false);
		}
	}
	const tagSelectedBiller = () => {
		var selectedBillers = billers.map((biller) => {
			if (biller.code === requestedBiller) {
				return { ...biller, isSelected: !biller.isSelected };
			}
			return { ...biller };
		});
		dispatch(
			listBillers({
				billers: selectedBillers.map((x) => ({
					...x,
				})),
			})
		);
	};

	const beforeRefresh = () => {
		if (hasValue(billers)) {
			billers.forEach(item => item.isSelected = false); // reset the selected biller to false
			setSessionStorage("billers", billers);
			getCategoryList(billers);
		}
	};
	
	// making sure user is in the favorite billers
	const checkBillerPaneStatus = () => {
		// check if the user is in the
		if (filterFavorite == true) {
			if (loadingIndicator == true) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};
	useEffect(() => {
		const handleUnload = (e) => {
			// Perform actions before the component unloads
			beforeRefresh();
		};
		window.addEventListener("unload", handleUnload);
		return () => {
			window.removeEventListener("unload", handleUnload);
		};
	}, [billers]);
	useEffect(() => {
		getBillerList();
	}, []);
	useEffect(() => {
		filterChange();
	}, [filterState, billers]);
	useEffect(() => {
		if (isBillerSuccess) {
			tagSelectedBiller();
		}
	}, [isBillerSuccess]);
	useEffect(() => {
		const timeId = setTimeout(() => {
			mutateNotificationState({ status: false });
		}, 4000);
		return () => {
			clearTimeout(timeId);
		};
	}, [notificationState.status]);
	const onClear = () => {
		searchRef.current.value = "";
		setSearchValue("");
		filterChange();
	};
	const replaceImage = (error) => {
		error.target.src =
			"https://cbci-mecom-dev-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/drafts/DEFAULT.png";
	};
	const onDragEnd = (event) => {
		if (!event.destination) {
			return;
		}
		if (event.source.index == event.destination.index) {
			return;
		}
		const favoriteList = filterFavoriteBillers(billers);
		const source = _.filter(favoriteList, [
			"orderFavorite",
			event.source.index + 1,
		]);
		const destination = _.filter(favoriteList, [
			"orderFavorite",
			event.destination.index + 1,
		]);
		// For drag and drop CSS Identifier
		setDragDestination(destination[0].code);
		setBillerSelected(source[0].name);
		handleDragFavorite(source, destination);
	};
	const getItemStyle = (isDragging, draggableStyle) => ({
		// styles we need to apply on draggables
		...draggableStyle,
		...(isDragging && {
			background: "#CACACA",
			height: "50px",
		}),
		height: "50px",
		background: "#F7FBFF",
		marginBottom: "4px",
		padding: "3px",
		borderRadius: "10px",
	});
	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "white" : "white",
	});
	return (
		<>
			<Box display={"grid"} gridAutoFlow={"row"} gap="5px">
				<Box gridRow={1 / -1} gridColumn={1 / -1}>
					<Box
						display={"flex"}
						justifyContent={"flex-end"}
						flexDirection={"column"}
						style={{ height: "100%" }}
					>
						<Fade
							in={notificationState.status}
							sx={{ zIndex: "modal" }}
						>
							<Alert
								variant="filled"
								className="alert_info"
								style={Object.assign(
									{},
									notificationState.type == 1 &&
										styles.alertSucess,
									notificationState.type == 0 &&
										styles.alertError
								)}
								severity={
									notificationState.type == 1
										? "success"
										: "error"
								}
							>
								{`${notificationState.name.replace(
									/\w+/g,
									_.capitalize
								)}${notificationState.message}`}
							</Alert>
						</Fade>
					</Box>
				</Box>
				<Box
					gridRow={1 / -1}
					gridColumn={1 / -1}
					style={{ minWidth: "220px" }}
				>
					<Box style={{ marginBottom: "4px" }}>
						<Tabs
							value={filterFavorite}
							disableRipple
							onChange={() => {
								handleTabChange();
							}}
							TabIndicatorProps={{
								style: {
									transition: "none",
									display: "none",
								},
							}}
							sx={{ maxHeight: "0px" }}
							style={{
								...(filterFavorite && {
									minHeight: "37px",
									height: "37px",
								}),
							}}
						>
							<Tab
								disableRipple
								label="Billers"
								value={false}
								icon={
									!filterFavorite ? (
										<AllBillerIconActive
											style={{
												height: "20px",
												width: "20px",
											}}
										/>
									) : (
										<AllBillerIcon
											style={{
												height: "20px",
												width: "20px",
											}}
										/>
									)
								}
								iconPosition="start"
								sx={styles.tab}
								style={{
									marginRight: "25px",
									marginLeft: "10px",
								}}
							/>
							<Tab
								disableRipple
								label="Favorite Billers"
								value={true}
								sx={styles.tab}
								icon={
									<Badge
										color="secondary"
										sx={{
											"& .MuiBadge-badge": {
												color: `${
													filterFavorite
														? "#FB7823"
														: "#8B9095"
												}`,
												backgroundColor: "#FBFBFB",
												borderColor: `${
													filterFavorite
														? "#FB7823"
														: "#8B9095"
												}`,
												borderStyle: "solid",
												borderWidth: "1px",
												height: "17px",
												maxWidth: "3px",
												fontSize: "9px",
											},
										}}
										badgeContent={favoritesCount}
									>
										<FavoriteIcon
											sx={{
												color: `${
													!filterFavorite
														? "#8B9095"
														: "#FB7823"
												}`,
											}}
										/>
									</Badge>
								}
								iconPosition="start"
							/>
						</Tabs>
						{!filterFavorite && (
							<>
								<Box display={"flex"} alignItems={"center"}>
									<FormControl
										variant="outlined"
										style={{
											minWidth: "calc(100% - 20%)",
										}}
									>
										<InputLabel
											className="category_label"
											htmlFor="outlined-age-native-simple"
										>
											Select Biller Categories
										</InputLabel>
										<Select
											fullWidth
											className="category_items"
											native
											value={selectedCategory}
											style={{ height: "42px" }}
											onChange={(e) => {
												handleSelectedCategoryChange(e);
											}}
											label={"Select Biller Categories "}
											inputProps={{
												name: "Select Biller Categories ",
												id: "outlined-age-native-simple",
											}}
										>
											<option value={"All"} key={"All"}>
												{"All"}
											</option>
											{categoryList.length > 0 &&
												categoryList.map((item) => (
													<option
														value={item}
														key={item}
													>
														{item}
													</option>
												))}
										</Select>
									</FormControl>
									<Box padding={"12px"}>
										<IconButton
											disableRipple
											style={{
												borderRadius: "8px",
												padding: "5px",
											}}
											onClick={() => {
												handleSortChange();
											}}
										>
											{isSortAsc ? (
												<SortDownIcon
													style={{
														height: "15px",
														width: "15px",
													}}
												/>
											) : (
												<SortUpIcon
													style={{
														height: "15px",
														width: "15px",
													}}
												/>
											)}
										</IconButton>
									</Box>
								</Box>
							</>
						)}
					</Box>
					{!filterFavorite && (
						<TextField
							fullWidth
							size="small"
							className="search"
							placeholder="Search biller here.."
							variant="standard"
							inputRef={searchRef}
							onChange={debounceOnChange}
							sx={{
								...styles.searchField.underlineColor,
							}}
							InputProps={{
								style: {
									...styles.searchField,
								},
								startAdornment: (
									<InputAdornment
										style={{ paddingRight: "4px" }}
									>
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment
										position="end"
										onClick={() => {
											onClear();
										}}
									>
										<ClearIcon
											style={{
												display: `${
													searchValue
														? "flex"
														: "none"
												}`,
												cursor: "pointer",
												height: "20px",
												width: "20px",
											}}
										/>
									</InputAdornment>
								),
							}}
						/>
					)}
					<Box
						style={{
							...(!filterFavorite
								? { height: "865px" }
								: { height: "965px" }),
							maxHeight: "100vh",
							overflow: "auto",
						}}
					>
						{isLoading && (
							<Box
								style={{
									height: "700px",
									maxHeight: "100vh",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Grid item>
									<CircularProgress />
								</Grid>
							</Box>
						)}
						{!billers.length && !isLoading && (
							<Box
								style={{
									height: "700px",
									maxHeight: "100vh",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Grid item>
									<Box
										display={"flex"}
										flexDirection={"column"}
										alignItems={"center"}
									>
										<ErrorOutlineRoundedIcon className="svg_icons" />
										<h5>There's no biller to show.</h5>
									</Box>
								</Grid>
							</Box>
						)}
						{favoritesCount == 0 &&
							filterFavorite == true &&
							isLoading !== true && (
								<Box
									style={{
										height: "700px",
										maxHeight: "100vh",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<Grid item>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												marginBottom: "15px",
											}}
										>
											<LightbulbIcon
												className="svg_icons"
												sx={{ color: "#FB9451" }}
											/>
										</Box>
										<Typography
											sx={{
												textAlign: "center",
												width: "200px",
											}}
											variant="body1"
											className="favorite-note"
										>
											Favorite some billers so you can
											quickly find it here.
										</Typography>
									</Grid>
								</Box>
							)}
						<List>
							{isLoading == false && (
								<>
									<DragDropContext
										onDragEnd={(e) => {
											onDragEnd(e);
										}}
									>
										<Droppable droppableId="droppable">
											{(provided, snapshot) => (
												<RootRef
													rootRef={provided.innerRef}
													key={provided.draggableId}
												>
													<List
														style={getListStyle(
															snapshot.isDraggingOver
														)}
														key={
															provided.draggableId
														}
														disablePadding
													>
														{billerList.map(
															(biller, index) => (
																<>
																	<Draggable
																		isDragDisabled={checkBillerPaneStatus()}
																		key={
																			biller.code
																		}
																		draggableId={
																			biller.code
																		}
																		index={
																			index
																		}
																	>
																		{(
																			provided,
																			snapshot
																		) => (
																			<Box
																				display={
																					"grid"
																				}
																			>
																				{(loadingIndicator ||
																					isLoadingSelectedBiller) &&
																					biller.name ==
																						billerSelected && (
																						<Box
																							gridRow={
																								1 /
																								-1
																							}
																							gridColumn={
																								1 /
																								-1
																							}
																							zIndex={
																								1600
																							}
																							xs={{
																								overflow:
																									"hidden",
																							}}
																							style={{
																								borderRadius:
																									"8px",
																								overflow:
																									"hidden",
																							}}
																						>
																							<LinearProgress
																								sx={{
																									...styles.billerLoading,
																								}}
																							/>
																						</Box>
																					)}
																				<Box
																					gridRow={
																						1 /
																						-1
																					}
																					gridColumn={
																						1 /
																						-1
																					}
																				>
																					<ListItem
																						ref={
																							provided.innerRef
																						}
																						{...provided.draggableProps}
																						{...provided.dragHandleProps}
																						sx={{
																							...(biller.isFavorite ===
																								0 && {
																								"&:hover > button:nth-child(3) > svg":
																									{
																										color: "#8B9095",
																									},
																							}),
																							...(dragDestination ==
																								biller.code && {
																								...styles.itemDestination,
																							}),
																						}}
																						style={getItemStyle(
																							snapshot.isDragging,
																							provided
																								.draggableProps
																								.style
																						)}
																						disablePadding
																					>
																						<ListItemIcon>
																							<div className="logo-wrapper">
																								<img
																									className={
																										"logo"
																									}
																									src={
																										biller.logo
																									}
																									onError={
																										replaceImage
																									}
																								/>
																							</div>
																						</ListItemIcon>
																						<ListItemText
																							primary={
																								<Typography
																									variant="body2"
																									className="label"
																								>
																									{
																										biller.name
																									}
																								</Typography>
																							}
																							disableTypography
																						></ListItemText>
																						{biller.isFavorite !==
																							1 &&
																							loadingIndicator !==
																								true &&
																							isLoading !==
																								true && (
																								<IconButton
																									onClick={() => {
																										handleAddToFavorites(
																											biller
																										);
																									}}
																									sx={{
																										"&:hover > svg":
																											{
																												color: "red",
																											},
																									}}
																								>
																									<Tooltip title="Add to favorites">
																										<FavoriteBorderRoundedIcon
																											fontSize={
																												"small"
																											}
																											className="heart"
																											sx={{
																												"&:hover":
																													{
																														color: "red",
																													},
																												transition:
																													"color",
																												color: "#F7FBFF",
																											}}
																										/>
																									</Tooltip>
																								</IconButton>
																							)}
																						{biller.isFavorite ==
																							1 &&
																							isLoadingSelectedBiller &&
																							biller.name !==
																								billerSelected &&
																							!loadingIndicator && (
																								<IconButton
																									disabled={
																										loadingIndicator
																									}
																									onClick={() => {
																										handleUnFavorite(
																											biller
																										);
																									}}
																								>
																									<Tooltip title="Remove to Favorites">
																										<FavoriteIcon
																											fontSize={
																												"small"
																											}
																											className="heart"
																											sx={{
																												"&:hover":
																													{},
																												transition:
																													"color",
																												color: "#FB7823",
																											}}
																										/>
																									</Tooltip>
																								</IconButton>
																							)}
																						{biller.isFavorite ==
																							1 &&
																							!isLoadingSelectedBiller && (
																								<IconButton
																									disabled={
																										loadingIndicator
																									}
																									onClick={() => {
																										handleUnFavorite(
																											biller
																										);
																									}}
																								>
																									<Tooltip title="Remove to Favorites">
																										<FavoriteIcon
																											fontSize={
																												"small"
																											}
																											className="heart"
																											sx={{
																												"&:hover":
																													{},
																												transition:
																													"color",
																												color: "#FB7823",
																											}}
																										/>
																									</Tooltip>
																								</IconButton>
																							)}
																						<ListItemIcon>
																							<ListItemSecondaryAction>
																								<IconButton
																									onClick={() => {
																										handleSelectedBiller(
																											biller
																										);
																									}}
																									disabled={
																										biller.isSelected ||
																										isLoadingSelectedBiller ||
																										loadingIndicator
																									}
																									sx={{
																										"&:hover":
																											{
																												color: "green",
																											},
																										transition:
																											"color",
																									}}
																								>
																									{biller.isSelected ==
																										false && (
																										<Tooltip title="Add to transaction">
																											<AddBillerIcon className="svg_small" />
																										</Tooltip>
																									)}
																									{biller.isSelected &&
																										!isLoadingSelectedBiller && (
																											<AddBillerActiveIcon className="svg_small" />
																										)}
																									{biller.isSelected &&
																										isLoadingSelectedBiller &&
																										biller.name !==
																											billerSelected && (
																											<AddBillerActiveIcon className="svg_small" />
																										)}
																								</IconButton>
																							</ListItemSecondaryAction>
																						</ListItemIcon>
																					</ListItem>
																				</Box>
																			</Box>
																		)}
																	</Draggable>
																</>
															)
														)}
														{provided.placeholder}
													</List>
												</RootRef>
											)}
										</Droppable>
									</DragDropContext>
								</>
							)}
						</List>
					</Box>
				</Box>
			</Box>
		</>
	);
}
export default BillerListPanel;