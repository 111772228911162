import { Button, Grid } from "@material-ui/core";
import { OrangeColorButton } from "../../BillSummary/BillSummary";
import config from "../config/biller.config.json";
import tStyles from "../TransactionList.module.css";
import iStyles from "./BillerFieldInquire.module.css";
import HTTP from "../../../helpers/ApiClient";
import { useEffect, useState } from "react";
import CustomField from "../Components/CustomField";
import GenericField from "../Components/GenericField";
import { getMonthName } from "../../../utils/dateUtil";
import { connect } from "react-redux";
import { updateBillerTxn } from "../../../redux/modules/billerList";
import _ from "lodash";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

function BillerFieldInquire(props: any) {
	const {
		data,
		classes,
		transactionKey,
		fieldErrors,
		updateBillerTxn,
		setFieldErrors,
		clickRemove,
		onChange,
		billerTransactions,
		failedTransactions,
		isValidated,
		...other
	} = props;
	//convert object to string then json
	const _config = JSON.parse(JSON.stringify(config));
	const _billerConfig = _config[data.code];
	const _customFields = _billerConfig.custom_fields;
	const _hiddenFields = _billerConfig.hidden_fields;

	//prop name of field to be inquired
	const _inquireProp = _billerConfig.inquireField.propName;

	const defaultState: any = {
		[_inquireProp]: "",
	};

	const [formState, setFormState] = useState(defaultState);
	const [shouldInquire, setShouldInquire] = useState({});
	const [error, setError] = useState();
	const [response, setResponse] = useState();
	const [loading, setLoading] = useState(false);

	const handleChange = (event: any) => {
		const _target = event.target;
		const _name = _target.name;
		setFormState({
			...formState,
			[_name]: _target.value,
		});

		setShouldInquire({
			shouldInquire: true,
			transactionKey: transactionKey,
			prop: _inquireProp,
		});
	};

	useEffect(() => {
		onChange(shouldInquire);
	}, [shouldInquire]);

	const handleInquire = () => {
		setLoading(true);

		inquire(data.code, formState[_inquireProp])
			.then((res) => {
				//Manipulate data to set PRN or BRN for SSS Contributions
				if (data.code === "SSS01") {
					//reset amount field'
					updateBillerTxn({
						code: data.code,
						txnKey: transactionKey,
						key: "amount",
						value: "",
					});
					updateBillerTxn({
						code: data.code,
						txnKey: transactionKey,
						key: "otherInfo.PaymentType",
						value: "",
					});

					setError(undefined);

					const payFor = Object?.prototype.hasOwnProperty.call(
						res.data.data.details,
						"PRN"
					)
						? "PRN"
						: "BRN";
					//add property to response for the switch
					res.data.data.details[_hiddenFields.switch] = payFor;
					updateBillerTxn({
						code: data.code,
						txnKey: transactionKey,
						key: "otherInfo.PayFor",
						value: payFor,
					});

					//Set date to month name
					res.data.data.details["BillDate"] = getMonthName(
						res.data.data.details.BillDate
					);

					setResponse(res.data.data.details);
				} else {
					setResponse(res.data);
				}
			})
			.catch((res) => {
				setError(res.response.data.data.message);
				setResponse(undefined);
			})
			.finally(() => {
				setLoading(false);
				setFieldErrors({});
				setShouldInquire({
					shouldInquire: false,
					transactionKey: transactionKey,
					prop: _inquireProp,
				});
			});
	};

	return (
		<Grid
			container
			justify="flex-start"
			direction="row"
			className={classes.root}
			style={{
				marginBottom: "30px",
			}}
		>
			<Grid item md={12}>
				<Grid container direction="row" spacing={1}>
					<GenericField
						classes={classes}
						field={_inquireProp}
						data={data}
						transactionKey={transactionKey}
						error={
							fieldErrors[transactionKey]?.transactions[
								_inquireProp
							]
								? fieldErrors[transactionKey]?.transactions[
										_inquireProp
								  ]
								: getFromTransactionErrors(
										failedTransactions,
										transactionKey,
										_inquireProp,
										data.code
								  ) != ""
								? getFromTransactionErrors(
										failedTransactions,
										transactionKey,
										_inquireProp,
										data.code
								  )
								: error
						}
						onChange={handleChange}
					/>
					<Grid item md={8}>
						<OrangeColorButton
							className={iStyles.inquireButton}
							variant="contained"
							onClick={handleInquire}
							disabled={
								loading ||
								checkButtonRules(
									formState[_inquireProp],
									_billerConfig.inquireField.rules
								)
							}
						>
							Inquire
						</OrangeColorButton>
						{isValidated ||
						Object?.prototype.hasOwnProperty.call(
							fieldErrors,
							transactionKey
						) ? (
							<>
								{getFromTransactionErrors(
									failedTransactions,
									transactionKey,
									_inquireProp,
									data.code
								) != "" ||
								Object?.prototype.hasOwnProperty.call(
									fieldErrors,
									transactionKey
								) ? (
									<ErrorIcon
										className={classes.errorValidateIcon}
									/>
								) : (
									<CheckCircleIcon
										className={classes.successValidateIcon}
									/>
								)}
								Convenience fee{" "}
								{
									billerTransactions[data.code].find(
										(txn: any) =>
											txn.transactionKey ===
											transactionKey
									)["otherCharges"]
								}
							</>
						) : null}
					</Grid>

					<Grid item md={1} alignItems="flex-end">
						<Button
							onClick={() =>
								clickRemove(data.code, transactionKey)
							}
						>
							<span className={tStyles.removeButton}>Remove</span>
						</Button>
					</Grid>
				</Grid>
				<Grid container direction="row" spacing={1}>
					<div style={{ minHeight: "4em" }} />
				</Grid>
				{response && (
					<Grid container direction="row" spacing={1}>
						{_hiddenFields[response[_hiddenFields.switch]].map(
							(field: any, i: number) => {
								const index = billerTransactions[
									data.code
								].findIndex((v: any) => {
									return v.transactionKey == transactionKey;
								});
								return (
									field && (
										<>
											{field.startsWith("custom") ? (
												<CustomField
													value={
														response[
															_customFields[field]
																.property
														]
													}
													classes={classes}
													field={_customFields[field]}
												/>
											) : (
												<GenericField
													classes={classes}
													field={field}
													data={data}
													transactionKey={
														transactionKey
													}
													error={
														fieldErrors[
															transactionKey
														]?.transactions[field]
															? fieldErrors[
																	transactionKey
															  ]?.transactions[
																	field
															  ]
															: getFromTransactionErrors(
																	failedTransactions,
																	transactionKey,
																	field,
																	data.code
															  ) != ""
															? getFromTransactionErrors(
																	failedTransactions,
																	transactionKey,
																	field,
																	data.code
															  )
															: error
													}
													value={
														billerTransactions[
															data.code
														][index][field]
													}
												/>
											)}
											{(i + 1) % 3 == 0 && ( //spacer/offset
												<Grid item md={3}></Grid>
											)}
										</>
									)
								);
							}
						)}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
}

function inquire(code: string, referenceNo: string) {
	return HTTP.get(`/v2/billers/${code}/inquiry/${referenceNo}`);
}

function checkButtonRules(value: any, rules: any) {
	let flag = false;
	Object.keys(rules).forEach((v) => {
		if (v == "chars") {
			flag = value.length != rules[v] ? true : false;
		}
	});
	return flag;
}
function getFromTransactionErrors(
	transactionErrors: any,
	transactionKey: string,
	field: string,
	code: string
) {
	if (transactionErrors[code]?.length > 0) {
		const _index = transactionErrors[code].findIndex((v: any) => {
			return v.transactionKey == transactionKey;
		});

		if (_index > -1) {
			if (transactionErrors[code][_index]["errors"][field]) {
				return transactionErrors[code][_index]["errors"][field][0][
					"message"
				];
			} else {
				return "";
			}
		} else {
			return "";
		}
	} else {
		return "";
	}
}
//for transaction list validation on validate
export function updateInquireArray(arr: any, val: any) {
	if (!_.isEmpty(val)) {
		const index = arr.findIndex((v: any) => {
			return v.transactionKey == val.transactionKey;
		});

		if (arr.length == 0) {
			arr = [val];
		} else if (index >= 0) {
			arr[index] = val;
		} else {
			arr.push(val);
		}
	}
	return arr;
}
export function checkConfig(code: string) {
	return Object?.prototype.hasOwnProperty.call(config, code);
}

export default connect(
	(state: any) => ({
		selectedBillers: state.billerList.selectedBillers,
		billerTransactions: state.billerList.billerTransactions,
		failedTransactions: state.validation.failedTransactions,
	}),
	{
		updateBillerTxn,
	}
)(BillerFieldInquire);
