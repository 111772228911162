import { useState,useEffect } from "react";
import {
	Dialog,
	Box,
	DialogActions,
	Button,
	Typography,
	DialogTitle,
	DialogContent,
} from "@material-ui/core";
import styles from "./DialogConfirmation.module.css";

const DialogConfirmation = (props) => {
	const {title, message, modalOpen, confirmation} = props

	const [open, setOpen] = useState(false);

	const handleConfirm =(confirm)=>{
		setOpen(false)
		confirmation(confirm)
	}

	useEffect(() => {
		setOpen(modalOpen);
	}, [modalOpen]);

	return (
		<Dialog onClose={()=>{setOpen(false)}} disableBackdropClick open={open} maxWidth="lg" >
			<DialogTitle id="customized-dialog-title" onClose={()=>{setOpen(false)}} >
				<Typography className={styles.header}>{title}</Typography>
			</DialogTitle>
			<DialogContent dividers>
				<Typography
					gutterBottom
					className={styles.dialogContent}
				>
					{message}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					className={styles.buttonWhite}
					onClick={()=>{handleConfirm(false)}}
				>
					No
				</Button>
				<Button
					variant="outlined"
					className={styles.buttonGreen}
					onClick={()=>{handleConfirm(true)}}
				>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogConfirmation;
