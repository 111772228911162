import { React } from "react";
import {
	Dialog,
	Box,
	DialogActions,
	Button,
	Typography,
} from "@material-ui/core";
import styles from "./Dialog.module.css";

const CustomDialog = ({ imgSrc, title, message, toggleDialog, open }) => {
	return (
		<Dialog
			disableBackdropClick
			classes={{ paper: styles.dialogPaper }}
			open={open}
			maxWidth="lg"
		>
			<Box>
				<img src={imgSrc} alt="icon" className={styles.img} />
			</Box>
			<Box>
				<Typography className={styles.header}>{title}</Typography>
			</Box>
			<Box>
				<Typography className={styles.body}>{message}</Typography>
			</Box>
			<DialogActions>
				<Button
					onClick={toggleDialog}
					variant="outlined"
					className={styles.button}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CustomDialog;
