import { useState, FC, useCallback, useEffect } from "react";
import AccessDeniedDialog from "../Dialog/Dialog";
import HTTP from "../../helpers/ApiClient";
import failedIcon from "../../assets/icons/ic-failed.svg";
import { setSessionStorage } from "../../utils/storage";
import { AxiosInstance } from "axios";

export type AccessObject = "TPAID" | "Branch" | "User";
type AccessCheckerProps = {
	accessType: AccessObject;
	value: string;
	callback: () => void;
};

export async function getEnabledStatus(
	value: string,
	accessObject: AccessObject,
	instance: AxiosInstance = HTTP
): Promise<boolean> {
	const apiUrls = {
		Branch: `/v3/branch-access/${value}/is-enabled`,
		TPAID: `/v3/terminal/${value}/is-enabled`,
		User: `/v3/channel/is-enabled?userHash=${value}`,
	};
	const { data } = await instance.get(apiUrls[accessObject]);

	if (Array.isArray(data.data)) {
		setSessionStorage("accountId", data.data[0].account_id);
		return data.data[0].is_enabled;
	}

	return data.data.is_enabled;
}

export const accessErrorMessages = {
	TPAID: (
		<>
			This TPAID is disabled at the moment.
			<br />
			Kindly contact your Admin for assistance.
		</>
	),
	Branch: (
		<>
			This Branch is disabled at the moment.
			<br />
			Kindly contact your Admin for assistance.
		</>
	),
	User: (
		<>
			This TPA/Channel Account is disabled
			<br />
			at the moment. Kindly contact your Admin
			<br />
			for assistance.
		</>
	),
};

const AccessChecker: FC<AccessCheckerProps> = ({
	accessType,
	value,
	callback = () => {},
}) => {
	const [visible, setVisible] = useState(false);

	const handleClose = useCallback(() => {
		setVisible(false);
		callback();
	}, [callback]);

	useEffect(() => {
		(async () => {
			if (value) {
				const enabled = await getEnabledStatus(value, accessType);
				if (!enabled) {
					setVisible(true);
				}
			}
		})();
	}, [value, accessType]);

	return (
		<AccessDeniedDialog
			imgSrc={failedIcon}
			open={visible}
			toggleDialog={handleClose}
			title="Access Denied!"
			message={accessErrorMessages[accessType]}
		/>
	);
};

export default AccessChecker;
