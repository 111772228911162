import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import styles from "./TransactionSummary.module.css";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Select from "../Inputs/Select/Select";
import Grid from "@material-ui/core/Grid";
import CustomTable from "../Table/CustomTable";
import { PrimaryTableCell } from "../Table/CustomTableCell";
import { PrimaryTableRow } from "../Table/CustomTableRow";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import PrintDropdown from "./PrintDropdown";
import {
	updateSelectedForPrint,
	showOrHideTransactionSummary,
	showPrintReceiptModal,
	togglePrintBillReceipt,
	printCheckFlowModal,
} from "../../redux/modules/transaction";
import { setReceiptNo } from "../../redux/modules/receipt";
import {
	generatePreFilledReceiptNo,
	generateString,
} from "../../utils/generateReceipt";
import HTTP from "../../helpers/ApiClient";
import { NavigationConfirmModal } from "react-router-navigation-confirm";
import { withRouter } from "react-router-dom";
import HelpIcon from "@material-ui/icons/Help";
import ReactToPrint, {
	useReactToPrint,
	PrintContextConsumer,
} from "react-to-print";
import PrintReceipt, { SSScode } from "../PrintReceipt/PrintReceipt";
import PrintReceiptSoa from "../PrintReceipt/PrintReceiptSoa";
import printJS from "print-js";
import { togglePrintReceiptModal } from "../../redux/modules/receipt";
import {
	clearBillerTxns,
	resetBillerTxn,
} from "../../redux/modules/billerList";
import {
	clearValidation,
	setPaymentDetails,
} from "../../redux/modules/validation";
import PrintCheckFlow from "../PrintCheckFlow/PrintCheckFlow";
import cx from "classnames";
import { TerminalUtil } from "../../utils/checkSession";
import { isEmpty } from "lodash";
import RecieptCheck from "../PrintReceipt/RecieptCheck";
import PrintRecieptCheck from "../PrintReceipt/PrintRecieptCheck";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import Error from "@material-ui/icons/Error";
import { Tooltip } from "@material-ui/core";

const DIGIT_COMMA_REGEX = /\B(?=(\d{3})+(?!\d))/g

const bayadStyle = `
 @page {
	margin: 0;
	page-break-after: avoid !important;
  }
`;

const soaStyle = `
 @page {
    margin: 0;
    page-break-after: avoid !important;
  }
`;

class TransactionSummary extends Component {
	constructor() {
		super();
		this.formatData = this.formatData.bind(this);
		this.disablePrintCheckPrintButton =
			this.disablePrintCheckPrintButton.bind(this);
		this.showPrintCheck = this.showPrintCheck.bind(this);
		this.onBillReceiptClick = this.onBillReceiptClick.bind(this);
		this.onControlModalConfirmButton =
			this.onControlModalConfirmButton.bind(this);
		this.printCheck = this.printCheck.bind(this);
		this.renderPrintType = this.renderPrintType.bind(this);
		this.toggleCheckReceiptFlow = this.toggleCheckReceiptFlow.bind(this);
		this.printBillsClose = this.printBillsClose.bind(this);
		this.printBillsCloseCheck = this.printBillsCloseCheck.bind(this);
		this.displayCheckPrintStatus = this.displayCheckPrintStatus.bind(this);
		this.state = { paymentStatus: "", printStatus: "", printType: "" };
		this.componentRef = React.createRef();
	}

	handlePrint(getCallback) {
		getCallback();
	}

	showPrintCheck() {
		return !isEmpty(this.props.checkReceiptsList);
	}

	disablePrintCheckPrintButton() {
		return (
			this.props.transactionList.filter(
				(x) =>
					x.printStatus.toUpperCase() === "PENDING" &&
					x.paymentStatus.toUpperCase() !== "FAILED"
			).length !== 0 ||
			["FAILED", "PRINTED"].includes(
				this.props.printCheckStatus.toUpperCase()
			) ||
			["FAILED", "PRINTED"].includes(
				this.props.printCheckStatus.toUpperCase()
			) ||
			["FAILED", "PRINTED"].includes(
				this.props.checkReceiptsList[0].printStatus.toUpperCase()
			)
		);
	}

	enableGoToPaybills() {
		return !(
			(this.props.checkReceiptsList.length &&
				["FAILED", "PRINTED"].includes(
					this.props.checkReceiptsList[0].printStatus.toUpperCase()
				)) ||
			(!this.props.checkReceiptsList.length &&
				this.props.transactionList.filter((x) =>
					["FAILED", "PRINTED"].includes(x.printStatus.toUpperCase())
				).length)
		);
	}

	async onBillReceiptClick(index, values) {
		try {
			const channelResponse = await HTTP.get(
				`/v2/accountname/${values.transactionNumber}`
			);
			let transactionIdList = [];

			transactionIdList = "'" + values.crn + "'";
			const resp =
				(await HTTP.get(
					`/v2/receipts/txn-details?crn=` + transactionIdList
				)) || {};

			const data = resp ? resp?.data?.data[0] : [];
			let trn = data.length === 0 ? values.transactionNumber : data.trn;
			this.props.setReceiptNo(data.receiptNo);
			let service_code = data.length === 0 ? "" : data.service_code;

			const sssData = {}
				//Remove inquire api for SSS 
				// SSScode.includes(service_code) &&
				// (await HTTP.get(
				// 	`/v2/billers/${service_code}/inquiry/${values.accountNo}?getFromCache=true`
				// )
				// 	.then(({ data }) => data.data && data.data.details)
				// 	.catch((e) => {
				// 		console.log("SSS Inquire Print Error: ", e);
				// 	}));
			const formattedData = {
				index,
				values: {
					...values,
					checkReceiptNo: values?.checkReceiptNo ? values.checkReceiptNo : null,
					otherInfo: data?.otherInfo,
					details: data?.details,
					service_code,
					channelName: channelResponse.data.channelName,
					transactionNumber: trn,
					sssData,
				},
			};
			this.props.updateSelectedForPrint(formattedData);
		} catch (e) {
			console.log(e);
		}
	}

	toggleCheckReceiptFlow() {
		this.props.printCheckFlowModal({ show: true });
	}

	renderPrintType() {
		if (this.state.printType === "Check")
			return <PrintRecieptCheck ref={(el) => (this.componentRef = el)} />;

		if (this.state.printType === "SOA")
			return <PrintReceiptSoa ref={(el) => (this.componentRef = el)} />;

		return <PrintReceipt ref={(el) => (this.componentRef = el)} />;
	}

	printCheck() {
		// const checkTransactions = this.props.transactionList.filter(
		// 	(x) =>
		// 		x.paymentMethod.toUpperCase().includes("CHECK") &&
		// 		x.paymentStatus.toUpperCase() !== "FAILED"
		// )[0];
		// this.setState({
		// 	printType: "Check",
		// });
		// await this.onBillReceiptClick(
		// 	index,
		// 	x
		// );
		// this.handlePrint(() => this.componentRef);

		// this.props.showPrintReceiptModal({
		// 	show: true,
		// 	data: {
		// 		values: checkTransactions,
		// 		index: 0,
		// 	},
		// });

		return (
			<ReactToPrint
				content={() => this.componentRef}
				bodyClass={cx({
					[styles.bodyPrintWindow]: this.state.printType !== "SOA",
				})}
				pageStyle={
					this.state.printType === "SOA" ? soaStyle : bayadStyle
				}
				onAfterPrint={this.printBillsCloseCheck}
			>
				<PrintContextConsumer>
					{({ handlePrint }) => (
						<PrintDropdown
							className={styles.printButton}
							classes={{ label: styles.label }}
							style={{
								margin: "8px 0px 27px 0px",
							}}
							disabled={this.disablePrintCheckPrintButton()}
							name="Print"
							menuitem={[
								{
									name: "Check",
									onClick: async () => {
										this.setState({
											printType: "Check",
										});
										await this.onBillReceiptClick(
											0,
											this.props.checkReceiptsList[0]
										);
										handlePrint(() => {});
									},
								},
							]}
							onUanbleClick={() => {
								this.props.showPrintReceiptModal({
									show: true,
									data: {
										values: this.props.checkReceiptsList[0],
										index: 0,
										type: "check",
									},
								});
							}}
						/>
					)}
				</PrintContextConsumer>
			</ReactToPrint>
		);

		// const receiptNumber =
		// 	checkTransactions.length === 1
		// 		? generatePreFilledReceiptNo() +
		// 		  checkTransactions[0].transactionNumber.substr(
		// 				checkTransactions[0].transactionNumber.length - 3
		// 		  ) +
		// 		  generateString()
		// 		: generatePreFilledReceiptNo() +
		// 		  checkTransactions[0].orderNumber.substr(
		// 				checkTransactions[0].orderNumber.length - 4
		// 		  ) +
		// 		  generateString();
		// const terminal = TerminalUtil.get();
		// const orderNumber =
		// 	checkTransactions.length === 1
		// 		? checkTransactions[0].transactionNumber
		// 		: checkTransactions[0].orderNumber;
		// const data = {
		// 	biller: checkTransactions[0].billerName,
		// 	accountNumber: checkTransactions[0].accountNo,
		// 	tpaid: terminal.name,
		// 	receiptNumber: receiptNumber,
		// 	transactionDatetime: checkTransactions[0].createdAt,
		// 	amount: checkTransactions[0].otherInfo.CheckDetails.Amount,
		// 	billsCount: checkTransactions.length,
		// 	checkNumber: checkTransactions[0].otherInfo.CheckDetails.CheckNo,
		// 	checkAcctNumber: "",
		// 	billerAcctNumber: checkTransactions[0].accountNo,
		// 	contactNumber:
		// 		checkTransactions[0].otherInfo.CheckDetails.ContactNumber,
		// 	orderNumber: orderNumber,
		// 	channelName: checkTransactions[0].channelName,
		// };
		// HTTP.post("/v2/receipts/check", JSON.stringify(data)).then((resp) => {
		// 	const pdf_url = resp.data.presigned_url;
		// 	fetch(pdf_url)
		// 		.then((resp) => resp.arrayBuffer())
		// 		.then((resp) => {
		// 			const file = new Blob([resp], { type: "application/pdf" });
		// 			printJS({
		// 				printable: URL.createObjectURL(file),
		// 				type: "pdf",
		// 				onPrintDialogClose: () => {
		// 					this.toggleCheckReceiptFlow();
		// 				},
		// 				onError: () => {
		// 					this.toggleCheckReceiptFlow();
		// 				},
		// 			});
		// 		});
		// });
	}

	printBillsClose() {
		this.props.togglePrintReceiptModal(true);
		this.props.togglePrintBillReceipt(false);
	}

	printBillsCloseCheck() {
		this.toggleCheckReceiptFlow();
		this.props.togglePrintBillReceipt(false);
	}

	formatData() {
		const data = this.props.transactionList.filter((x) => {
			let ret = true;

			if (
				this.state.printStatus !== "" ||
				this.state.paymentStatus !== ""
			) {
				ret =
					(this.state.printStatus === ""
						? true
						: this.state.printStatus.toUpperCase() ===
						  x.printStatus.toUpperCase()) &&
					(this.state.paymentStatus === ""
						? true
						: this.state.paymentStatus.toUpperCase() ===
						  x.paymentStatus.toUpperCase());
			}

			return ret;
		});
		return data.map((x, index) => {
			let ret = [
				x.billerName,
				x.accountNo,
				x.transactionNumber === "" ? "-" : x.transactionNumber,
				parseFloat(x.amount).toFixed(2).toString().replace(DIGIT_COMMA_REGEX, ","),
			];

			if (x.paymentStatus.toUpperCase() !== "FAILED") {
				ret.push(
					<span style={{ color: "#28A745" }}>{x.paymentStatus}</span>,
					<span
						style={{
							color:
								x.printStatus.toUpperCase() === "PRINTED"
									? "#28A745"
									: "#F26122",
						}}
					>
						{x.printStatus}
					</span>,
					{
						value: (
							<>
								<Button
									variant="outlined"
									startIcon={<VisibilityIcon />}
									className={styles.previewButton}
									classes={{ label: styles.label }}
									style={{ margin: "0px 5px 0px 0px" }}
								>
									Preview
								</Button>
								<ReactToPrint
									content={() => this.componentRef}
									bodyClass={cx({
										[styles.bodyPrintWindow]:
											this.state.printType !== "SOA",
									})}
									pageStyle={
										this.state.printType === "SOA"
											? soaStyle
											: bayadStyle
									}
									onAfterPrint={this.printBillsClose}
								>
									<PrintContextConsumer>
										{x.billerName.toUpperCase() ===
										"MERALCO"
											? ({ handlePrint }) => (
													<PrintDropdown
														className={
															styles.printButton
														}
														classes={{
															label: styles.label,
														}}
														style={{
															margin: "0px 0px 0px 5px",
														}}
														disabled={[
															"FAILED",
															"PRINTED",
														].includes(
															x.printStatus.toUpperCase()
														)}
														menuitem={[
															{
																name: "Bayad Form",
																onClick:
																	async () => {
																		this.setState(
																			{
																				printType:
																					"Bayad Form",
																			}
																		);
																		await this.onBillReceiptClick(
																			index,
																			x
																		);
																		handlePrint(
																			() =>
																				this
																					.componentRef
																		);
																	},
															},
															{
																name: "SOA",
																onClick:
																	async () => {
																		this.setState(
																			{
																				printType:
																					"SOA",
																			}
																		);
																		await this.onBillReceiptClick(
																			index,
																			x
																		);
																		handlePrint(
																			() =>
																				this
																					.componentRef
																		);
																	},
															},
														]}
														name="Print"
														onUanbleClick={() => {
															this.props.showPrintReceiptModal(
																{
																	show: true,
																	data: {
																		values: x,
																		index,
																	},
																}
															);
														}}
													/>
											  )
											: ({ handlePrint }) => (
													<PrintDropdown
														className={
															styles.printButton
														}
														classes={{
															label: styles.label,
														}}
														style={{
															margin: "0px 0px 0px 5px",
														}}
														disabled={[
															"FAILED",
															"PRINTED",
														].includes(
															x.printStatus.toUpperCase()
														)}
														menuitem={[
															{
																name: "Bayad Form",
																onClick:
																	async () => {
																		this.setState(
																			{
																				printType:
																					"Bayad Form",
																			}
																		);
																		await this.onBillReceiptClick(
																			index,
																			x
																		);
																		handlePrint(
																			() =>
																				this
																					.componentRef
																		);
																	},
															},
														]}
														name="Print"
														onUanbleClick={() => {
															this.props.showPrintReceiptModal(
																{
																	show: true,
																	data: {
																		values: x,
																		index,
																	},
																}
															);
														}}
													/>
											  )}
									</PrintContextConsumer>
								</ReactToPrint>
							</>
						),
						style: { maxWidth: "500px" },
					}
				);
			} else {
				ret.push(
					<span style={{ color: "#D93025" }}>{x.paymentStatus}</span>,
					"",
					""
				);
			}

			return ret;
		});
	}

	onControlModalConfirmButton() {
		this.props.showOrHideTransactionSummary(false);
		this.props.history.push("/pay-bills");
	}

	resetTransaction() {
		this.props.clearValidation();
		this.props.clearBillerTxns();
		this.props.setPaymentDetails({});
		this.props.resetBillerTxn();
	}

	displayCheckPrintStatus() {
		if (
			this.props.checkReceiptsList[0].printStatus.toUpperCase() ===
			"PENDING"
		)
			return;

		if (
			this.props.checkReceiptsList[0].printStatus.toUpperCase() ===
			"FAILED"
		)
			return (
				<Tooltip title="Print Failed">
					<Error
						style={{
							marginLeft: 14,
							fontSize: 14,
							verticalAlign: "middle",
							color: "#D72E22",
						}}
					/>
				</Tooltip>
			);
		if (
			this.props.checkReceiptsList[0].printStatus.toUpperCase() ===
			"PRINTED"
		)
			return (
				<Tooltip title="Print Success">
					<CheckCircle
						style={{
							marginLeft: 14,
							fontSize: 14,
							verticalAlign: "middle",
							color: "#28A745",
						}}
					/>
				</Tooltip>
			);
	}

	render() {
		return (
			<>
				<PrintCheckFlow printType={this.state.printType} />
				{this.props.isReloadLocked && (
					<NavigationConfirmModal
						onConfirm={this.onControlModalConfirmButton}
						confirmText="Continue"
						buttonClassName={styles.confirmModalButton}
						bodyClassName={styles.confirmModalBody}
					>
						<Grid container>
							<Grid item xs={2}>
								<HelpIcon
									style={{
										fontSize: "50px",
										color: "#4876B0",
										margin: "20px 10px 10px 10px",
									}}
								/>
							</Grid>
							<Grid item xs={10}>
								<p>
									The page that you're looking for used
									information that you entered. Returning to
									that page might cause any action you took to
									be repeated. Do you want to continue?
								</p>
							</Grid>
						</Grid>
					</NavigationConfirmModal>
				)}
				<Container>
					<p className={styles.title}>Transaction Summary</p>
					<Paper className={styles.filterContainer} elevation={0}>
						<Container>
							<Grid container spacing={2}>
								<Grid item>
									<Box
										display="flex"
										justifyContent="center"
										alignItems="center"
										height="100%"
									>
										<FormLabel
											className={styles.filterLabel}
										>
											Filter:
										</FormLabel>
									</Box>
								</Grid>

								<Grid item lg={2}>
									<Select
										label="Payment Status"
										options={[
											{
												value: "Success",
												name: "Success",
											},
											{ value: "Failed", name: "Failed" },
										]}
										selected={this.state.paymentStatus}
										onChange={(e) => {
											this.setState({
												paymentStatus: e.target.value,
											});
										}}
									/>
								</Grid>
								<Grid item lg={2}>
									<Select
										label="Print Status"
										options={[
											{
												value: "Printed",
												name: "Printed",
											},
											{
												value: "Pending",
												name: "Pending",
											},
											{ value: "Failed", name: "Failed" },
										]}
										selected={this.state.printStatus}
										onChange={(e) => {
											this.setState({
												printStatus: e.target.value,
											});
										}}
									/>
								</Grid>
							</Grid>
						</Container>
					</Paper>
					<CustomTable
						CustomTableCell={PrimaryTableCell}
						columns={[
							"BILLER",
							"ACCOUNT NUMBER",
							"TRANSACTION NUMBER",
							"AMOUNT",
							"Payment Status",
							"Print Status",
							"",
						]}
						CustomTableRow={PrimaryTableRow}
						data={this.formatData()}
					/>
					<Box display="flex" flexDirection="row-reverse">
						<Grid item lg={3}>
							{this.showPrintCheck() && (
								<Paper
									className={styles.printCheckContainer}
									elevation={0}
								>
									<p className={styles.printCheckTitle}>
										<span
											style={{ verticalAlign: "middle" }}
										>
											Print Check
										</span>
										{this.displayCheckPrintStatus()}
									</p>
									<div>
										<Button
											variant="outlined"
											startIcon={<VisibilityIcon />}
											className={styles.previewButton}
											classes={{ label: styles.label }}
											style={{
												margin: "8px 5px 27px 0px",
											}}
										>
											Preview
										</Button>
										{this.printCheck()}
									</div>
								</Paper>
							)}
							<Button
								variant="contained"
								fullWidth
								size="large"
								color="primary"
								disabled={this.enableGoToPaybills()}
								classes={{ disabled: styles.buttonDisabled }}
								style={{ margin: "11px 0px" }}
								onClick={() => {
									this.props.showOrHideTransactionSummary(
										false
									);
									this.resetTransaction();
								}}
							>
								Go to Pay Bills
							</Button>
						</Grid>
					</Box>
				</Container>
				<div style={{ display: "none" }}>{this.renderPrintType()}</div>
			</>
		);
	}
}

export default connect(
	(state) => ({
		transactionList: state.transaction.transactionList,
		checkReceiptsList: state.transaction.checkReceiptsList,
		printCheckStatus: state.transaction.printCheckStatus || "PENDING",
		selectedForPrint: state.transaction.selectedForPrint,
		isReloadLocked: state.timeout.isReloadLocked,
	}),
	{
		setReceiptNo,
		updateSelectedForPrint,
		showOrHideTransactionSummary,
		showPrintReceiptModal,
		togglePrintReceiptModal,
		togglePrintBillReceipt,
		printCheckFlowModal,
		clearBillerTxns,
		resetBillerTxn,
		clearValidation,
		setPaymentDetails,
	}
)(withRouter(TransactionSummary));
